import * as XLSX from "sheetjs-style";


class ExcelUtils {

    static addSheetAppend (sheet, data) {
        XLSX.utils.sheet_add_aoa(
            sheet,
            [data],
            { origin: -1 }
        );
    }

    static addStyleHeaderCell(headers, rows, numberRow) {

        const headerStyle = {
            font: {bold: true, sz: '16'},
            width: 50
        };

        for (let colIndex = 0; colIndex < headers.length; colIndex++) {
            const headerCellRef = XLSX.utils.encode_cell({r: numberRow, c: colIndex});
            rows[headerCellRef].s = headerStyle;
        }
        return headerStyle;
    }

    static adjustColumnsWidth(sheets) {
        // Ajustar automáticamente el ancho de todas las columnas al contenido
        const range = XLSX.utils.decode_range(sheets['!ref']);
        for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            let maxWidth = 0;
            for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
                const cellRef = XLSX.utils.encode_cell({r: rowIndex, c: colIndex});
                const cell = sheets[cellRef];
                const text = cell && cell.v !== undefined ? cell.v.toString() : '';
                const width = text.length + 8; // Puedes ajustar este valor según tus necesidades
                if (width > maxWidth) {
                    maxWidth = width;
                }
            }
            sheets['!cols'] = sheets['!cols'] || [];
            sheets['!cols'][colIndex] = {wch: maxWidth};
        }
    }
}

export default ExcelUtils;