import React from "react";
import SocketClient from "../../socket/SocketClient";
import Toasts from "../notificacion/Toasts";
import {StateContext} from "../context/StateProvider";


class Socket extends React.Component {

    static contextType = StateContext;

    componentDidMount() {

        const {state, updateState} = this.context;

        const socketClient = new SocketClient();
        socketClient.connect();
        socketClient.receiveUpdateVersion(function(message) {
            console.log(message);
            const toasts = Toasts.generateToast('Nueva version detectara, se va a reiniciar aplicación en 5 seg', 'warning');
            updateState({toasts: [...state.toasts, toasts]})
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        })
    }

    render() {
        return(<>
        </>);
    }
}

export default Socket