import React from "react";
import ProductService from "../services/product.service";
import ListProducts from "../components/lists/ListProducts";
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CSpinner} from "@coreui/react";
import {StateContext} from "../components/context/StateProvider";
import ProductFactory from "../models/ProductFactory";

class StockOrder extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {threshold: '', orders: ''};
    }

    handleChange(event) {
        this.setState({threshold: event.target.value});
    }

    handleChangeOrders(event) {
        this.setState({orders: event.target.value});
    }

    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const {updateState} = this.context;

        updateState({products: []})
        if (this.state.value !== "") {

            updateState({loading: true});

            await ProductService.getStockOrderProducts(this.state.threshold, this.state.orders).then(
                async (data) => {
                    const productMap = data.map(async (product) => {

                        return ProductFactory.product(product.CODIGO,
                            product.BarrasModels,
                            product.NOMBRE,
                            product.UBICACION,
                            product.VENSERIE,
                            product.Art_FotoModels,
                            product.MulticamModels,
                            product.Stocks2Models,
                            product.REPONER
                        );
                    });

                    const productsMapFull = await Promise.all(productMap);
                    updateState({products: productsMapFull})
                    updateState({loading: false});
                }
            );
        }
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        updateState({toasts: []})
        updateState({products:[]});
    }

    render() {

        const {state} = this.context;

        return (
            <CCol id="stock" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <CForm onSubmit={(e) => this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="threshold" className="form-label">Umbral de stock</label>
                                <CFormInput className="form-control" type="text" id="threshold" name="threshold"
                                       value={this.state.threshold}
                                       onChange={(e) => this.handleChange(e)}/>
                                <label htmlFor="orders" className="form-label mt-2">Excluir pedidos</label>
                                <CFormInput className="form-control" type="text" id="orders" name="orders" value={this.state.orders}
                                       onChange={(e) => this.handleChangeOrders(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                {state.loading ?
                                    <CSpinner component="span" size="sm"
                                              aria-hidden="true"/> : 'Buscar'}
                            </CButton>
                        </CForm>
                        <ListProducts updateProducts="0" selectProducts="1"/>
                    </CCardBody>
                </CCard>
            </CCol>
        );
    }
}

export default StockOrder;