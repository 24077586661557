import React, { useState, useContext, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormSelect, CSpinner } from '@coreui/react';
import Order, {OrderType, orderTypesWithLabels} from "../models/Order";
import {StateContext} from "../components/context/StateProvider";
import SocketClient from "../socket/SocketClient";
import SalesService from "../services/sales.service";
import ListOrder from "../components/lists/ListOrder";
import ListOrderInfo from "../components/lists/ListOrderInfo";
import useSalesOrder from "../components/hooks/useSalesOrder";
import Utils from "../utils/Utils";

const Info = () => {

    const {state, updateState} = useContext(StateContext);
    const {getSales, checkOrderN2} = useSalesOrder()
    const [order, setOrder] = useState('');
    const [orderType, setOrderType] = useState(OrderType.Albaran.valueOf());
    const [listOrderInfo, setListOrderInfo] = useState([]);

    useEffect(() => {
        const interval = setInterval(() => checkOrderN2(null), 120000);
        const socketClient = new SocketClient();
        socketClient.connect();

        socketClient.receiveUpdateSale(async (data) => {
            if (data[0].pedido === order) {
                await getSales(order, 1);
            }
        });

        return () => {
            clearInterval(interval);
            updateState({ toasts: [] });
            updateState({ products: [] });
            updateState({ order: new Order() });
            updateState({ year: Utils.getCurrentYear() });
            socketClient.disconnect();
        };
    }, []);

    const handleChangeYear = (event) => {
        updateState({ year: parseInt(event.target.value) });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (orderType === OrderType.Albaran.valueOf()) {
            if (order !== "" && Utils.checkOrder(order)) {
                updateState({ loading: true });
                await getSales(order, 1);
            } else if (order !== "" && !Utils.checkOrder(order)) {
                const toasts = Toasts.generateToast('Formato de albarán inválido: SERIE-ALBARAN', 'error');
                updateState({ toasts: [...state.toasts, toasts] });
            }
        } else {
            const listOrderInfo = await SalesService.getSales(order, orderType, state.year)
            setListOrderInfo(listOrderInfo);
            updateState({ products: [] });
        }
    };

    const handleInputFocus = () => {
        setOrder('');
    };

    const handleChange = (event, type) => {
        if (type === 'orderType') {
            setOrderType(event.target.value);
            setListOrderInfo([]);
            setOrder('');
            updateState({ products: [] });
        } else {
            setOrder(event.target.value);
        }
    };

    return (
        <CCol id="sales" md={12}>
            <CCard className="p-3">
                <CCardBody>
                    <CForm onSubmit={handleSubmit} className="mb-3">
                        <div className="mb-2">
                            <label htmlFor="list-year" className="form-label">Año</label>
                            <CFormSelect className='form-control mb-2'
                                         onChange={handleChangeYear}
                                         id="list-year"
                                         name="list-year"
                                         aria-label="Seleccionar año"
                                         options={Utils.getArrayYears()} />
                        </div>
                        <div className="mb-2">
                            <label htmlFor="list-order-type" className="form-label">Tipo</label>
                            <CFormSelect className='form-control mb-2'
                                         onChange={(e) => handleChange(e, 'orderType')}
                                         id="list-order-type"
                                         name="list-order-type"
                                         aria-label="Seleecionar tipo"
                                         options={orderTypesWithLabels} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="order" className="form-label">Nº</label>
                            <CFormInput className="form-control" type="text" id="order" name="order"
                                        value={order}
                                        onFocus={handleInputFocus}
                                        onChange={(e) => handleChange(e, 'order')} />
                        </div>
                        <CButton type="submit" color="primary" className="px-4 col-12">
                            {state.loading ?
                                <CSpinner component="span" size="sm"
                                          aria-hidden="true" /> : 'Buscar'}
                        </CButton>
                    </CForm>
                    <ListOrderInfo listOrderInfo={listOrderInfo} />
                    <ListOrder nave={'1'} type={'albarán'} handle={'ventas'}
                               order={order} preparer={true} reviewer={true} camera={true} orderStateN2={false} />
                </CCardBody>
            </CCard>
        </CCol>
    );
};

export default Info;