import React, {useState, useContext, useEffect} from 'react';
import {CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormSelect, CSpinner} from "@coreui/react";
import Utils from "../utils/Utils";
import {StateContext} from "../components/context/StateProvider";
import Toasts from "../components/notificacion/Toasts";
import successSound from "../assets/audio/success.mp3";
import errorSound from "../assets/audio/error.mp3";
import OrderService from "../services/order.service";
import OrderStates from "../utils/OrderStates";
import AuthService from "../services/auth.service";
import Roles from "../config/roles";


const Orders = () => {

    const user = AuthService.getCurrentUser();
    const {state, updateState} = useContext(StateContext);
    const [order, setOrder] = useState('');
    const [orderState, setOrderStateType] = (user.roles.includes(Roles.ADMIN.role) ?
        useState(OrderStates.getStatesJson()[0].value) :
        useState(OrderStates.getStatesJson()[1].value));

    const [orderStateOptions, setOrderStateOptions] =
        (user.roles.includes(Roles.ADMIN.role) ?
            useState(OrderStates.getStatesJson()) :
            useState([{label: OrderStates.getState(2), value: 2}]));

    const handleChangeYear = (event) => {
        updateState({year: parseInt(event.target.value)});
    };

    const handleInputFocus = () => {
        setOrder('');
    };

    const handleChange = (event, type) => {
        if (type === 'orderState') {
            setOrderStateType(event.target.value);
        } else {
            setOrder(event.target.value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let toasts;
        if (order !== "" && Utils.checkOrder(order)) {
            updateState({loading: true});
            try {
                await OrderService.assignToOrder(order, null, null, 'ventas', orderState, null, undefined, state.year, null);
                toasts = Toasts.generateToast(`${order} ha sido cambiado al estado: <strong>${OrderStates.getState(orderState).toLowerCase()}</strong>`, 'success');
                Utils.playAudio(successSound);
            } catch (error) {
                toasts = Toasts.generateToast(error.response.data.message, 'error');
                Utils.playAudio(errorSound);
            }
            updateState({loading: false});
            setOrder('');

        } else if (order !== "" && !Utils.checkOrder(order)) {
            toasts = Toasts.generateToast('Formato de albarán inválido: SERIE-ALBARAN', 'error');
            Utils.playAudio(errorSound);
        }

        updateState({toasts: [...state.toasts, toasts]});
    }

    return (
        <CCol id="sales" onSubmit={handleSubmit} md={12}>
            <CCard className="p-3">
                <CCardBody>
                    <CForm className="mb-3">
                        <div className="mb-2">
                            <label htmlFor="list-year" className="form-label">Año</label>
                            <CFormSelect className='form-control mb-2'
                                         onChange={handleChangeYear}
                                         id="list-year"
                                         name="list-year"
                                         aria-label="Seleccionar año"
                                         options={Utils.getArrayYears()}/>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="list-order-type" className="form-label">Estado</label>
                            <CFormSelect className='form-control mb-2'
                                         onChange={(e) => handleChange(e, 'orderState')}
                                         id="list-order-type"
                                         name="list-order-type"
                                         aria-label="Seleecionar tipo"
                                         options={orderStateOptions}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="order" className="form-label">Nº</label>
                            <CFormInput className="form-control" type="text" id="order" name="order"
                                        value={order}
                                        onFocus={handleInputFocus}
                                        onChange={(e) => handleChange(e, 'order')}
                            />
                        </div>
                        <CButton type="submit" color="primary" className="px-4 col-12">
                            {state.loading ?
                                <CSpinner component="span" size="sm"
                                          aria-hidden="true"/> : 'Cambiar estado'}
                        </CButton>
                    </CForm>
                </CCardBody>
            </CCard>
        </CCol>
    );
}

export default Orders;