import React from "react";
import SalesService from "../services/sales.service";
import {CBadge, CButton, CCard, CCardBody, CCardTitle, CCol, CForm, CFormInput, CSpinner,} from "@coreui/react";
import Utils from "../utils/Utils";
import OrderFactory from "../models/OrderFactory";
import OrderService from "../services/order.service";
import OrderStates from "../utils/OrderStates";
import Toasts from "../components/notificacion/Toasts";
import {StateContext} from "../components/context/StateProvider";
import {DataGrid, esES, GridActionsCellItem, GridRowEditStopReasons, GridRowModes} from "@mui/x-data-grid";
import VoiceUtils from "../utils/VoiceUtils";
import Filters from "../components/filter/Filters";
import ListProducts from "../components/lists/ListProducts";
import ProductService from "../services/product.service";
import ProductFactory from "../models/ProductFactory";
import Delivery from "../models/Delivery";
import DeliveryService from "../services/delivery.service";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {Box} from "@mui/material";


class SalesN2 extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {orders: [], ordersModels: {}, loading: true, order: ''};
    }

    componentDidMount() {
        const {updateState} = this.context;

        this.getData(false);
        this.interval = setInterval(() => this.getData(true), 120000);

        updateState({filters: [{field: 'state', operator: 'isAnyOf', value: ['1', '2']}]});
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        if (this.interval) {
            clearInterval(this.interval); // Limpia el intervalo al desmontar el componente
        }
        updateState({filters: []});
    }

    /**
     * Obtener albaranes con productos en nave 2
     * @returns {Promise<void>}
     */
    async getData(checkNewOrders) {
        const {state} = this.context;

        const orders = await SalesService.getSalesN2(state.year);

        if (orders.length > this.state.orders.length && checkNewOrders) {
            VoiceUtils.speak('Atención! Hay pedidos nuevos');
        }

        const ordersMap = orders.map(async (order) => {

            let orderN2 = OrderFactory.OrderN2(order.LETRA+'-'+order.NUMERO, new Date(order.FECHA), order.ClientesModel?.NOMBRE, order.ClientesModel?.RutasModel?.NOMBRE, order.TRANSPORTE);

            try {
                const orderState = await OrderService.getStateOrderN2(order.LETRA+'-'+order.NUMERO, state.year);

                if (orderState.length > 0) {
                    orderN2.preparer = (orderState[0].preparado === null ? '0' : orderState[0].preparado);
                    orderN2.state = (orderState[0].estado === null ? '1' : orderState[0].estado);
                    orderN2.exclude = orderState[0].excluir;

                    if (orderN2.state === 'preparado') {
                        orderN2.complete = true;
                    }
                }

                if (orderN2.state === '1') {
                    if (order.TRANSPORTE === undefined) {
                        orderN2.priority = 2;
                    } else if (order.TRANSPORTE?.toLowerCase().includes('recojo')) {
                        orderN2.priority = 1;
                    }
                }

            } catch (error) {
                const {state, updateState} = this.context;
                const toasts = Toasts.generateToast(error?.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            }


            return orderN2;
        })

        const salesMapFull = await Promise.all(ordersMap);
        this.setState({orders: [...salesMapFull].sort((a, b) => (a.priority < b.priority ? 1 : -1)), loading: false});
    }

    /**
     * Definición columnas de la tabla
     * @returns {[{headerName: string, field: string, flex: number},{headerName: string, field: string, flex: number, renderCell: (function(*): string)},{filterMode: string, headerName: string, field: string, flex: number, renderCell: (function(*): string)},{headerName: string, field: string, flex: number, renderCell: (function(*): string)},{headerName: string, field: string, flex: number, renderCell: (function(*): string)},null]}
     */
    generateColumns() {

        return [
            {
                field: 'num',
                headerName: 'Albarán',
                flex: 1,
                renderCell: (params) => <a
                    href={'#/pedidosn2/order/' + params.value + '?ruta=' + params.row.route}
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    {params.value}
                </a>
            },
            {
                field: 'date',
                headerName: 'Fecha',
                flex: 1,
                renderCell: (params) => Utils.formatDateWithoutHour(params.value),
            },
            {
                field: 'customer',
                headerName: 'Cliente',
                flex: 1,
                renderCell: (params) => Utils.toCapitalize(params.value),
                filterMode: 'client'
            },
            {
                field: 'route',
                headerName: 'Ruta',
                flex: 1,
                renderCell: (params) => Utils.toCapitalize(params.value),
            },
            {
                field: 'transport',
                headerName: 'Transporte',
                flex: 1,
                renderCell: (params) => Utils.toCapitalize(params.value),
            },
            {
                field: 'priority',
                headerName: 'Prioridad',
                flex: 1,
                hide: true,
                renderCell: (params) => params.value,
            },
            {
                field: 'state',
                headerName: 'Estado',
                flex: 1,
                filterable: false,
                renderCell: (params) => <CBadge
                    color={params.value === '1' ? 'info' : (params.value === '2' ? 'danger' : 'success')}>{OrderStates.getState(params.value)}</CBadge>,
            },
            {
                field: 'exclude',
                headerName: 'No preparar',
                type: 'boolean',
                flex: 1,
                editable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 100,
                cellClassName: 'actions',
                getActions: ({id}) => {
                    const isInEditMode =
                        this.state.ordersModels[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon/>}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => this.handleSave(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon/>}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => this.handleCancel(id)}
                                color="inherit"
                            />,
                        ];
                    }

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => this.handleEdit(id)}
                            color="inherit"
                        />
                    ];
                },
            },
        ];
    }

    handleChange(event) {
        this.setState({order: event.target.value});
    }

    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        const {state, updateState} = this.context;

        if (this.state.order !== "") {

            updateState({loading: true});

            await SalesService.reInsertOrderN2(this.state.order, state.year).then(
                async (data) => {
                    console.log(data);
                    let type;
                    if (data.details?.existen) {
                        type = 'success';
                        this.setState({loading: true, order: ''});
                        this.getData(false).then(() => {
                            this.setState({loading: false});
                        });

                    } else {
                        type = 'warning';
                    }

                    const toasts = Toasts.generateToast(data.message, type);
                    updateState({toasts: [...state.toasts, toasts]})
                    updateState({loading: false});
                }
            );
        }
    }

    handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({
            ordersModels: newRowModesModel,
        });
    };

    handleEdit(id) {
        this.setState({
            ordersModels: {
                ...this.state.ordersModels,
                [id]: {mode: GridRowModes.Edit},
            },
        });
    }

    handleSave = (id) => {
        this.setState({
            ordersModels: {...this.state.ordersModels, [id]: {mode: GridRowModes.View}},
        });
    };

    handleCancel = (id) => {
        this.setState({
            ordersModels: {
                ...this.state.ordersModels,
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
            },
        });

        const editedRow = this.state.orders.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.setState({
                deliveries: this.state.orders.filter((row) => row.id !== id),
            });
        }
    };

    processRowUpdate = async (newRow) => {

        const {state, updateState} = this.context;

        if (!newRow.isNew) {
            OrderService.assignToOrder(newRow.num, null, null, 'ventas', null, true, newRow.exclude, state.year, null).then(
                () => {
                    const toasts = Toasts.generateToast('Se ha modificado pedido correctamente', 'success');
                    updateState({toasts: [...state.toasts, toasts]})
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            });
        }

        const updatedRow = {...newRow, isNew: false};
        this.setState({
            deliveries: this.state.orders.map((row) => (row.id === newRow.id ? updatedRow : row)),
        });
        return updatedRow;
    };

    // /**
    //  * Filtrado de lista
    //  * @param value
    //  */
    // filterList(value) {
    //     const {updateState} = this.context;
    //
    //     let optios = [];
    //     Array.from(value).forEach((element) => {
    //         optios.push(element.value);
    //     });
    //
    //     updateState({filters: [{field: 'state', operator: 'isAnyOf', value: optios}]});
    // }

    render() {

        const {orders, loading} = this.state;
        const {state} = this.context;
        const columns = this.generateColumns();

        return (
            <CCol id="sales" md={12}>
                <CCard className="p-3 mb-3">
                    <CCardBody>
                        <CForm onSubmit={(e) => this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="threshold" className="form-label">Recuperar pedido</label>
                                <CFormInput className="form-control" type="text" id="order" name="order"
                                            value={this.state.order}
                                            onChange={(e) => this.handleChange(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                {state.loading ?
                                    <CSpinner component="span" size="sm"
                                              aria-hidden="true"/> : 'Recuperar'}
                            </CButton>
                        </CForm>
                        <ListProducts updateProducts="0" selectProducts="1"/>
                    </CCardBody>
                </CCard>
                <CCard className="p-3">
                    <CCardTitle className="mt-3 ms-3 me-3">Pedidos con productos en nave 2</CCardTitle>
                    <CCardBody>
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <CSpinner animation="border" className='m-5 text-center' role="status"/>
                            </div>
                        ) : (
                            <>
                                {/*<Filters orderStates={true} filter={(value) => this.filterList(value)}/>*/}
                                <Box
                                    sx={{
                                        '& .exclude': {
                                            backgroundColor: '#ff943975',
                                            color: '#1a3e72',
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={orders}
                                        columns={columns}
                                        getRowId={(orders) => orders.num}
                                        rowModesModel={this.state.ordersModels}
                                        onRowEditStop={this.handleRowEditStop}
                                        onRowModesModelChange={this.handleRowModesModelChange}
                                        processRowUpdate={this.processRowUpdate}
                                        // filterModel={{
                                        //     items: state.filters
                                        // }}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    priority: false,
                                                },
                                            }
                                        }}
                                        getRowClassName={(params) => {
                                            return params.row.exclude ? "exclude" : "";
                                        }}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        disableRowSelectionOnClick={true}
                                        disableVirtualization={true}
                                    />
                                </Box>
                            </>
                        )}
                    </CCardBody>
                </CCard>
            </CCol>
        );
    }
}

export default SalesN2;