import {format} from "date-fns";

export default class Order {

    constructor() {
        this.num = null;
        this.preparer = '0';
        this.reviewer = '0';
        this.complete = false;
        this.lastUpdated = null;
        this.route = null;
        this.customer = null;
        this.transport = null;
        this.date = null;
        this.state = '1';
        this.stateN2 = '1';
        this.n2 = false;
        this.preparerN2 = '0';
        this.priority = 0;
        this.exclude = false;
        this.camera = null;
        this.sticker = null;
        this.delivery = null;
        this.packages = null;
        this.lastRegisterPackage = null;
        this.editing = false;
    }

    setLastUpdated (lastUpdated) {
        this.lastUpdated = format(lastUpdated, 'dd/MM/yyyy HH:mm:ss');
    }
}

export const OrderType = {
    Albaran: 'albaran',
    Factura: 'factura',
    Pedido: 'pedido'
}

export const orderTypesWithLabels = Object.keys(OrderType).map(key => ({
    label: key,
    value: OrderType[key]
}));