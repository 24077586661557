import React from "react";
import ProductService from "../../services/product.service";
import {CButton} from "@coreui/react";
import HistoryProduct from "./HistoryProduct";
import ModalEan from "../modals/EanProduct";
import {StateContext} from "../context/StateProvider";
import Toasts from "../notificacion/Toasts";


class Product extends React.Component {

    static contextType = StateContext;

    /**
     * Seleccionar producto
     * @param index
     */
    handleSelectProduct(index) {

        const {state, updateState} = this.context;

        if (state.products[index].checked === undefined || state.products[index].checked === false)
            state.products[index].checked = true;
        else
            state.products[index].checked = false;

        updateState({products: state.products})
    }

    /**
     * Actualizar producto
     * @param index
     * @param recovery
     */
    handleUpdateProduct(index, recovery) {

        const {state, updateState} = this.context;

        if (Object.keys(state.products[index].updated).length !== 0 || recovery) {
            ProductService.updateProduct(state.products[index].ref, state.products[index].updated, recovery).then(
                result => {

                    if (result.data) {

                        if (result.data.actualizado && state.products[index].updated.ubicacion) {
                            state.products[index].revert = true;
                        }

                        if (recovery) {
                            state.products[index].location = result.data.ubicacion;
                            state.products[index].revert = false;
                        }
                    }

                    const toasts = Toasts.generateToast(result.message, 'success');
                    state.products[index].updated = {};
                    updateState({products: state.products});
                    updateState({toasts: [...state.toasts, toasts]})
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
                updateState({products: state.products});
            });
        }
    }

    /**
     * Mostrar historico de ubicaciones
     * @param index
     */
    handleHistoryLocation(index) {

        const {state, updateState} = this.context;

        if (state.products[index].showHistory === true) {
            state.products[index].showHistory = false;
            updateState({products: state.products});
        } else {
            ProductService.getProductHistory(state.products[index].ref).then(
                result => {
                    if (result.length === 0) {
                        const toasts = Toasts.generateToast('No hay histórico de producto', 'warning');
                        updateState({toasts: [...state.toasts, toasts]})
                        state.products[index].showHistory = false;
                    } else {
                        state.products[index].showHistory = true;
                    }

                    state.products[index].history = result;
                    updateState({products: state.products});
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
                updateState({products: state.products});
            });
        }
    }

    /**
     * Actualizar ubicacion
     * @param event
     * @param index
     */
    handleChangeLocation(event, index) {

        const {state, updateState} = this.context;

        state.products[index].location = event.target.value;
        state.products[index].updated.ubicacion = event.target.value;

        updateState({products: state.products});
    }

    /**
     * Obtener imagen
     * @param images
     * @returns {string}
     */
    getImageProduct(images) {

        if (images !== undefined) {
            const index = images.findIndex(
                (image) => {
                    return image.ORDEN === true;
                }
            );
            if (index !== -1) {
                return ProductService.generateImageUrl(images[index].IMAGEN.toLowerCase());
            }

            return ProductService.generateImageUrl('not-found')
        }
    }

    render() {

        const {index, update, select} = this.props;
        const {state} = this.context;
        const product = state.products[index];

        return (
            <li className="list-group-item border-top">
                <div className="flex-column flex-lg-row pt-3 pb-3">
                    <div className="row">
                        <div
                            className="list-image-product text-center col-12 col-sm-3 order-sm-2 aspect-ratio aspect-ratio-4x3">
                            <img src={this.getImageProduct(product.images)}
                                 className="img-fluid h-100 aspect-ratio-item" alt={product.name}/>
                        </div>
                        <div className="col-12 col-sm-9 order-sm-1">
                            {select==="1" ?
                                <input type="checkbox"
                                       checked={product.checked} onChange={() => {
                                    this.handleSelectProduct(index)
                                }}/>
                                : ''}
                            <div className="row">
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-2 me-2">Nombre:</label>
                                    <div className="form-text mt-0 mb-2">{product.name}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-2 me-2">Referencia:</label>
                                    <div className="form-text mt-0 mb-2">{product.ref}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-2 me-2">Código barras:</label>
                                    {product.ean.length > 0 !== '' ?
                                        <div className="form-text mt-0 mb-2">
                                            {product.ean?.map((item, index) => {
                                                return (<span key={index}>{item.BARRAS}-{item.UNIDADES}</span>)
                                            })}
                                        </div>
                                        : ''}
                                    {update === "1" ?
                                        <ModalEan index={index}/>
                                        : ''}
                                </div>
                            </div>
                            {
                                product.fields?.length > 0 && product.fields?.map((item, index) => {
                                    return (
                                        <div key={index}
                                             className={(item.CAMPO === '117' || item.CAMPO === '118') && (item.VALOR === '' || item.VALOR === '0') ? 'd-none' : 'row'}>
                                            <div className="col-12 d-inline-flex align-items-center">
                                                <label className="form-label mb-2 me-2">
                                                    {item.CAMPO === '###' ? 'Publicado web:' : ''}
                                                    {item.CAMPO === '117' ? 'Caja individual:' : ''}
                                                    {item.CAMPO === '118' ? 'Caja grande:' : ''}
                                                </label>
                                                <div className="form-text mt-0 mb-2">
                                                    {item.CAMPO === '###' ? item.VALOR === '.T.' ? 'No' : 'Si' : ''}
                                                    {(item.CAMPO === '117' || item.CAMPO === '118') ? item.VALOR : ''}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                product.stock?.length > 0 && product.stock?.map((item, index) => {
                                    return (
                                        <div key={index} className="row">
                                            <div className="col-12 d-inline-flex align-items-center">
                                                <label className="form-label mb-2 me-2">Stock
                                                    nave {item.ALMACEN === '03' ? '1' : item.ALMACEN === '05' ? '4' : '3'}:</label>
                                                <div className="form-text mt-0 mb-2">{item.FINAL}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {product.replace > 0 ?
                                <div className="row">
                                    <div className="col-12 d-inline-flex align-items-center">
                                        <label className="form-label mb-2 me-2">Reponer nave 1:</label>
                                        <div className="form-text mt-0 mb-2">{product.replace}</div>
                                    </div>
                                </div>
                                : ''}
                            <div className="row">
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-1 me-2">Ubicación:</label>
                                    {update === "1" ?
                                        <input className="form-control mt-0 mb-1 p-1" type="text" value={product.location}
                                               onChange={(e) => this.handleChangeLocation(e, index)}/>
                                        : <div className="form-text mt-0 mb-1"> {product.location}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {update === "1" ?
                        <div className="mt-3 product-action">
                            <div className="text-start">
                                <CButton className="btn btn-primary" onClick={() => {
                                    this.handleUpdateProduct(index, false)
                                }}>Actualizar
                                </CButton>
                                <CButton className="btn btn-secondary" onClick={() => {
                                    this.handleHistoryLocation(index)
                                }}>Ver histórico ubicaciones
                                </CButton>
                                {product.revert !== undefined && product.revert === true ?
                                    <CButton className="btn btn-warning" onClick={() => {
                                        this.handleUpdateProduct(index, true)
                                    }}>Deshacer ubicación</CButton>
                                    : ''}
                            </div>
                            {product.showHistory !== undefined && product.showHistory === true ?
                                <HistoryProduct history={product.history}/>
                                : ''}
                        </div>
                        : ''}
                </div>
            </li>
        );
    }
}

export default Product;