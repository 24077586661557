import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from '../../routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import parse from "html-react-parser";

const Breadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    const matchingRoute = routes.find((route) => {
      const routePathSegments = route.path.split('/');
      const pathnameSegments = pathname.split('/');

      if (routePathSegments.length !== pathnameSegments.length) {
        return false;
      }

      let routeNameWithParam = '';

      for (let i = 0; i < routePathSegments.length; i++) {
        if (routePathSegments[i] !== pathnameSegments[i] && !routePathSegments[i].startsWith(':')) {
          return false;
        }

        if (routePathSegments[i].startsWith(':')) {
          // Si el segmento de ruta comienza con ':', es un parámetro dinámico
          const paramValue = pathnameSegments[i]; // Obtenemos el valor del parámetro
          routeNameWithParam += `${paramValue}`;
        }

        if (i < routePathSegments.length - 1) {
          routeNameWithParam += ' ';
        }
      }

      route.nameWithParam = routeNameWithParam; // Agregar la propiedad al objeto de ruta

      return true;
    });

    return matchingRoute ?  matchingRoute.name + matchingRoute.nameWithParam : false;
  };

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem
            {...(breadcrumb.active ? { active: true } : { href: '/#'+breadcrumb.pathname })}
            key={index}
          >
            {decodeURIComponent(breadcrumb.name)}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(Breadcrumb)
