import axios from "axios";
import HostService from "./host.service";
import AuthService from "./auth.service";


class EmployeesService {

    /**
     * Obtener empleados
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getEmployees() {
        return axios.get(HostService.getHost() + 'empleados', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Crear empleado
     * @returns {Promise<AxiosResponse<any>>}
     */
    static createEmployee(name) {
        return axios.post(HostService.getHost() + 'empleados', { nombre: name }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Modificar empleado
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updateEmployee(id, name) {
        return axios.put(HostService.getHost() + 'empleados?id='+id, { nombre: name }, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }

    /**
     * Borrar empleado
     * @returns {Promise<AxiosResponse<any>>}
     */
    static deleteEmployee(id) {
        return axios.delete(HostService.getHost() + 'empleados?id='+id, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            });
    }
}

export default EmployeesService;