


class HostService {

    /**
     * Obtener si estas desde dentro de la red de mandatelo o fuera
     * @returns {*}
     */
    static getHost = () => {
        const host = window.location.hostname;
        return host === process.env.REACT_APP_IP_INTERNAL ? process.env.REACT_APP_API_URL_INTERNAL : process.env.REACT_APP_API_URL_EXTERNAL;
    }

}

export default HostService;