import React from "react";
import {CCard, CCardBody, CCol} from "@coreui/react";
import ListOrder from "../components/lists/ListOrder";
import SalesService from "../services/sales.service";
import Order from "../models/Order";
import ProductFactory from "../models/ProductFactory";
import {StateContext} from "../components/context/StateProvider";
import withRouter from "../components/withRouter";
import OrderService from "../services/order.service";
import Utils from "../utils/Utils";


class OrderN2 extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        const orderNum = this.props.params.order;

        const {updateState, state} = this.context;

        await SalesService.getSalesProductN2(orderNum, state.year).then(
            async (data) => {

                const {state} = this.context;
                const stateContext = state;

                let order = new Order();
                order.route = this.props.search.get('ruta');

                let complete = 0;
                let lastUpdated = null;

                const salesMap = data.map(async (sale, index) => {

                    let state = 0;
                    let remaining = sale.UNIDADES;
                    let register = 0;
                    let registerDate = undefined;
                    let manual = false;

                    if (!Utils.checkRefProduct(sale.ARTICULO)) {

                        let refArticulo = sale.ARTICULO;

                        if (sale.DUPLICADO) {
                            refArticulo += '-' + sale.LINIA;
                        }

                        // Obtener ventas
                        const saleRegistry = await SalesService.getSalesProduct(sale.LETRA+'-'+sale.NUMERO, refArticulo, stateContext.year, 2);

                        // Comprobar ventas quedan quedan por añadir
                        if (saleRegistry.length > 0) {
                            remaining = sale.UNIDADES - saleRegistry[0].unidades;
                            register = saleRegistry[0].unidades;

                            // Cambiar el estado en funcion de los bultos
                            if (remaining === 0) {
                                state = 1;
                                complete += 1;
                            }

                            registerDate = saleRegistry[0].registrado;
                            manual = saleRegistry[0].manual;

                            if (lastUpdated === null || lastUpdated < new Date(saleRegistry[0].registrado)) {
                                lastUpdated = new Date(saleRegistry[0].registrado);
                            }
                        }

                    } else {
                        sale.UNIDADES = 0;
                        remaining = 0;
                        state = 1;
                        complete += 1;
                    }

                    let barrasModels = 'BarrasModels';
                    if (stateContext.year !== Utils.getCurrentYear()) {
                        barrasModels = 'BarrasModel'+stateContext.year+'s';
                    }

                    return ProductFactory.productOrder(sale.LETRA+'-'+sale.NUMERO, sale.ARTICULO, sale.INFO_ARTICULO[barrasModels], sale.INFO_ARTICULO.NOMBRE,
                        sale.UNIDADES, sale.INFO_ARTICULO.UBICACION, sale.INFO_ARTICULO.VENSERIE, remaining, state, register, sale.DUPLICADO, sale.LINIA, registerDate, manual, index);
                });

                const orderState = await OrderService.getStateOrderN2(orderNum, state.year);

                if (orderState.length > 0) {
                    order.preparer = (orderState[0].preparado === null ? '0' : orderState[0].preparado);
                    order.state = orderState[0].estado;

                    // Descomentar para no poder actualizar pedido una vez preparado
                    // if (order.state === '2') {
                    //     order.complete = true;
                    // }
                }

                const salesMapFull = await Promise.all(salesMap);

                order.num = orderNum;
                order.n2 = true;
                order.complete = complete === data.length;
                updateState({order: order});
                updateState({products: salesMapFull})
                updateState({loading: false});
                updateState({filters: []})
            }
        );
    }

    componentWillUnmount() {
        const {updateState} = this.context;
        updateState({toasts: []})
        updateState({products:[]});
        updateState({order: new Order()});
    }

    render() {
        return (
            <CCol id="sales" md={12}>
                <CCard className="p-3">
                    <CCardBody>
                        <ListOrder nave={'2'} type={'albarán'} registerAction={SalesService.registerSale} handle={'ventas'} read={false} preparer={true} reviewer={false} camera={false} orderStateN2={true}/>
                    </CCardBody>
                </CCard>
            </CCol>
        )
    }
}

export default withRouter(OrderN2);