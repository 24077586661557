import React, {useState, useContext, useEffect} from 'react';
import {CBadge, CCard, CCardBody, CCol} from "@coreui/react";
import SalesService from "../services/sales.service";
import {StateContext} from "../components/context/StateProvider";
import {DataGrid, esES, GridActionsCellItem} from "@mui/x-data-grid";
import OrderStates from "../utils/OrderStates";
import OrderService from "../services/order.service";
import Toasts from "../components/notificacion/Toasts";
import Utils from "../utils/Utils";
import successSound from "../assets/audio/success.mp3";
import errorSound from "../assets/audio/error.mp3";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EngineeringIcon from '@mui/icons-material/Engineering';

const StateOrders = () => {

    const {state, updateState} = useContext(StateContext);
    const [orders, setOrders] = useState([]);
    const [ordersModels, setOrdersModels] = useState({});
    const [loading, setLoading] = useState(true);
    const apiRef = React.createRef();
    apiRef.current = {};

    useEffect(() => {
        SalesService.getStatesSales(state.year).then((data) => {
            setOrders(data.map((row, index) => ({ ...row, id: index + 1 })));
            setLoading(false);
        });
    }, [state.year]);

    const handleChangeState = async (id, orderState) => {

        let row = apiRef.current.getRow(id);
        const order = row.serie+'-'+row.pedido;

        let toasts;
        try {
            await OrderService.assignToOrder(order, null, null, 'ventas', orderState, null, undefined, state.year, null);
            toasts = Toasts.generateToast(`${order} ha sido cambiado al estado: <strong>${OrderStates.getState(orderState).toLowerCase()}</strong>`, 'success');
            Utils.playAudio(successSound);
            row.estado = orderState;
            apiRef.current.updateRows([
                {
                    id: row.id,
                    ...row,
                },
            ]);
        } catch (error) {
            toasts = Toasts.generateToast(error.response.data.message, 'error');
            Utils.playAudio(errorSound);
        }
        updateState({loading: false});
        updateState({toasts: [...state.toasts, toasts]});
    }

    const generateColumns = () => {
        return [
            {
                field: 'seriePedido',
                headerName: 'Albarán',
                valueGetter: (params) => `${params.row.serie}-${params.row.pedido}`,
                flex: 1,
            },
            {
                field: 'preparado',
                headerName: 'Preparando',
                flex: 1,
            },
            {
                field: 'revisado',
                headerName: 'Revisando',
                flex: 1,
            },
            {
                field: 'camara',
                headerName: 'Camara',
                flex: 1,
            },
            {
                field: 'estado',
                headerName: 'Estado',
                flex: 1,
                filterable: false,
                renderCell: (params) => <CBadge
                    color={params.value === '1' ? 'info' : (params.value === '2' ? 'danger' : 'success')}>{OrderStates.getState(params.value)}</CBadge>,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 150,
                cellClassName: 'actions',
                getActions: ({id}) => {

                    return [
                        <GridActionsCellItem
                            icon={<ClearIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleChangeState(id, '1')}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<EngineeringIcon/>}
                            label="Delete"
                            onClick={() => handleChangeState(id, '2')}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CheckIcon/>}
                            label="Check"
                            onClick={() => handleChangeState(id, '3')}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
    };

    return (
        <CCol id="sales" md={12}>
            <CCard className="p-3">
                <CCardBody>
                    <DataGrid
                        rows={orders}
                        columns={generateColumns()}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        autoHeight={true}
                        loading={loading}
                        apiRef={apiRef}
                        rowModesModel={ordersModels}
                    />
                </CCardBody>
            </CCard>
        </CCol>
    );
};

export default StateOrders;