class Employee {

    constructor(id, name) {
        this.id = id;
        this.code = id;
        this.name = name;
    }

}

export default Employee;