import Product from "./Product";


class ProductFactory {

    static productOrder(order, ref, ean, name, units, location, serie, remaining, state, register, duplicate, line, registerDate, manual, id) {
        return new Product(order, ref, ean, name, units, location, serie, remaining, state, register, undefined, undefined, undefined, undefined, duplicate, line, registerDate, manual, id);
    }

    static product(ref, ean, name, location, serie, images, fields, stock, replace) {
        return new Product(undefined, ref, ean, name, undefined, location, serie, undefined, undefined, undefined, images, fields, stock, replace);
    }
}

export default ProductFactory;