import axios from "axios";
import HostService from "./host.service";


class AuthService {

    /**
     * Login
     * @param username
     * @param password
     * @returns {Promise<AxiosResponse<any>>}
     */
    static login = (username, password) => {

        return axios
            .post(HostService.getHost() + "login", {
                username,
                password,
            })
            .then((response) => {
                return response.data;
            });
    };

    /**
     * Realizar logout
     */
    static logout = () => {
        localStorage.removeItem("__permissionUser");
    };

    /**
     * Obtener el usuario actual
     * @returns {any}
     */
    static getCurrentUser = () => {
        return JSON.parse(localStorage.getItem("__permissionUser"));
    };

    /**
     * Añadir token
     * @returns {{headers: {authorization: CancelToken}}}
     */
    static setToken() {
        const user = this.getCurrentUser();
        const config = {
            headers: {authorization: user.token}
        };
        return config;
    }
}

export default AuthService;
