import React, {Component} from 'react'

import {CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler} from '@coreui/react'
import {StateContext} from "../context/StateProvider";
import navigation from '../../_nav'
import {SidebarNav} from "./SidebarNav";

import logo from "../../assets/brand/logo.png";
import logo_s from "../../assets/brand/logo-small.png";

import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

class Sidebar extends Component {

    static contextType = StateContext;

    render() {

        const {state, updateState} = this.context;

        return (
            <CSidebar
                position="fixed"
                unfoldable={state.sidebarUnfoldable}
                visible={state.sidebarShow}
                onVisibleChange={(visible) => {
                    updateState({sidebarShow: visible})
                }}
            >
                <CSidebarBrand className="d-none d-md-flex" to="/">
                    <img className="sidebar-brand-full" src={logo} alt="Mandatelo" width="180"/>
                    <img className="sidebar-brand-narrow" src={logo_s} alt="Mandatelo" width="40"/>
                </CSidebarBrand>
                <CSidebarNav>
                    <SimpleBar>
                        <SidebarNav items={navigation}/>
                    </SimpleBar>
                </CSidebarNav>
                <CSidebarToggler
                    className="d-none d-lg-flex"
                    onClick={() => updateState({sidebarUnfoldable: !state.sidebarUnfoldable})}
                />
            </CSidebar>
        )
    }
}


export default React.memo(Sidebar)
