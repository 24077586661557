import React from "react";
import {
    DataGrid,
    esES,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from "@mui/x-data-grid";
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CancelIcon from '@mui/icons-material/Close';
import {Button} from "@mui/material";
import {CCard, CCardBody, CCol} from "@coreui/react";
import DeliveryService from "../services/delivery.service";
import {StateContext} from "../components/context/StateProvider";
import Toasts from "../components/notificacion/Toasts";
import Delivery from "../models/Delivery";
import {CropFree, StickyNote2, Visibility} from "@mui/icons-material";
import withRouter from "../components/withRouter";
import ConfirmationM from "../components/modals/ConfirmationM";

class Deliveries2 extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {deliveries: [], deliveriesModels: {}, transports: [], maxId: 0, loading: true, text: '', title: '', idDelivery: ''};
    }

    async componentDidMount() {
        // Obtener rutas
        await DeliveryService.getDeliveries().then(
            (data) => {

                const maxId = data.reduce((max, current) => {
                    return current.id > max ? current.id : max;
                }, -1);

                this.setState({deliveries: data})
                this.setState({maxId: maxId})
                this.setState({loading: false});
            }
        ).catch((error) => {
            this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
        });

        // Obtener transportes
        await DeliveryService.getTransports().then(
            (data) => {
                const transports = data.map(option => ({
                    value: option.CODIGO,
                    label: option.CODIGO+'-'+option.NOMBRE,
                }));
                this.setState({transports: transports})
            }
        ).catch((error) => {
            this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
        });
    }

    handleNavigateTo = (url) => {
        const {navigate} = this.props;
        navigate(url);
    }

    generateColumns() {
        const columns = [
            {
                field: 'id',
                headerName: 'Id',
                flex: 1,
                hide: true,
            },
            {
                field: 'name',
                headerName: 'Nombre',
                flex: 1,
                editable: true,
            },
            {
                field: 'date',
                headerName: 'Fecha',
                type: 'date',
                flex: 0.75,
                editable: true,
                valueGetter: params => params.value !== undefined ? new Date(params.value) : new Date(),
            },
            {
                field: 'transport',
                headerName: 'Transportista',
                type: 'singleSelect',
                flex: 2,
                editable: true,
                valueOptions: this.state.transports,
                valueGetter: (params) => params.value || '000',
            },
            {
                field: 'panel',
                headerName: 'Visible panel',
                type: 'boolean',
                flex: 0.5,
                editable: true,
            },
            {
                field: 'asigna',
                headerName: 'Asigna',
                type: 'boolean',
                flex: 0.5,
                editable: true,
            },
            {
                field: 'barito',
                headerName: 'Barito',
                type: 'boolean',
                flex: 0.5,
                editable: true,
            },
            {
                field: 'desk',
                headerName: 'Mostrador',
                type: 'boolean',
                flex: 0.5,
                editable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                flex: 1.5,
                cellClassName: 'actions',
                getActions: ({id}) => {
                    const isInEditMode =
                        this.state.deliveriesModels[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon/>}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => this.handleSave(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon/>}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => this.handleCancel(id)}
                                color="inherit"
                            />,
                        ];
                    }

                    const delivery = this.state.deliveries.find(delivery => delivery['id'] === id);

                    return [
                        <GridActionsCellItem
                            icon={<StickyNote2/>}
                            label="Stickers"
                            className="textPrimary"
                            onClick={() => this.handleNavigateTo('/panel/etiquetas/' + delivery.name + '?id=' + id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<Visibility/>}
                            label="Check"
                            className="textPrimary"
                            onClick={() => this.handleNavigateTo('/panel/etiquetas/' + delivery.name + '?id=' + id + '&view=true')}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CropFree/>}
                            label="Push"
                            className="textPrimary"
                            onClick={() => this.handleNavigateTo('/panel/rutas/' + id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => this.handleEdit(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={() => this.handleConfirmDelete(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];


        return columns;
    }

    handleSave = (id) => {
        this.setState({
            deliveriesModels: {...this.state.deliveriesModels, [id]: {mode: GridRowModes.View}},
        });
    };

    handleCancel = (id) => {
        this.setState({
            deliveriesModels: {
                ...this.state.deliveriesModels,
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
            },
        });

        const editedRow = this.state.deliveries.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.setState({
                deliveries: this.state.deliveries.filter((row) => row.id !== id),
            });
        }
    };

    handleEdit(id) {
        this.setState({
            deliveriesModels: {
                ...this.state.deliveriesModels,
                [id]: {mode: GridRowModes.Edit},
            },
        });
    }

    async handleConfirmDelete(id) {

        const delivery = this.state.deliveries.find((row) => row.id === id);

        this.setState({
            showConfirmation: true,
            text: '¿Esta seguro que quiere eliminar la ruta: <strong>' + delivery.name + '<\strong>',
            title: 'Atención',
            disableConfirm: false,
            idDelivery: id
        });
    }

    async handleDelete(id) {
        // Borrar en el estado
        this.setState({
            deliveries: this.state.deliveries.filter((row) => row.id !== id),
        });

        // Borrar en petición servidor
        await DeliveryService.deleteDelivery(id).then(
            () => {
                this.context.updateState({toasts: [...this.context.state.toasts,
                        Toasts.generateToast('Se ha eliminado ruta correctamente', 'success')]})
            }
        ).catch((error) => {
            this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
        });

        this.handleClose();
    }

    handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({
            deliveriesModels: newRowModesModel,
        });
    };

    processRowUpdate = async (newRow) => {

        if (newRow.isNew) {
            await DeliveryService.createDelivery(newRow).then(
                () => {
                    this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast('Se ha creado ruta correctamente', 'success')]})
                }
            ).catch((error) => {
                this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
            });
        } else {
            await DeliveryService.updateDelivery(newRow, true).then(
                () => {
                    this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast('Se ha modificado ruta correctamente', 'success')]})
                }
            ).catch((error) => {
                this.context.updateState({toasts: [...this.context.state.toasts, Toasts.generateToast(error.message, 'error')]})
            });
        }

        const updatedRow = {...newRow, isNew: false};
        this.setState({
            deliveries: this.state.deliveries.map((row) => (row.id === newRow.id ? updatedRow : row)),
        });
        return updatedRow;
    };

    editToolbar = () => {
        const handleClick = () => {
            const id = this.state.maxId + 1;
            let delivery = new Delivery(id, '');
            delivery.isNew = true;
            this.setState({maxId: id});
            this.setState({deliveries: [...this.state.deliveries, delivery]})
            this.setState({
                deliveriesModels: {
                    ...this.state.deliveriesModels,
                    [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'}
                }
            })
        };

        return (
            <GridToolbarContainer>
                <Button startIcon={<AddIcon/>} className='bg-primary text-white mb-3' onClick={() => handleClick()}>
                    Añadir ruta
                </Button>
            </GridToolbarContainer>
        );
    }

    handleClose = () => {
        this.setState({showConfirmation: false});
    }

    render() {
        const {
            showConfirmation,
            title,
            text
        } = this.state;

        return (
            <CCol id="deliveries" md={12}>
                <CCard className="p-3">
                    <CCardBody>

                        <ConfirmationM show={showConfirmation}
                                       title={title}
                                       text={text}
                                       iconName='cilWarning'
                                       disableConfirm={false}
                                       handleClose={this.handleClose}
                                       handleConfirm={() => this.handleDelete(this.state.idDelivery)}/>

                        <DataGrid
                            rows={this.state.deliveries}
                            columns={this.generateColumns()}
                            rowModesModel={this.state.deliveriesModels}
                            onRowEditStop={this.handleRowEditStop}
                            onRowModesModelChange={this.handleRowModesModelChange}
                            processRowUpdate={this.processRowUpdate}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            editMode="row"
                            autoHeight={true}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'date', sort: 'desc'}],
                                }
                            }}
                            slots={{
                                toolbar: this.editToolbar,
                            }}
                            loading={this.state.loading}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        );
    }
}

export default withRouter(Deliveries2);