import Order from "./Order";


class OrderFactory {

    static OrderN2(num, date, customer, route, transport, exclude) {
        let order = new Order();
        order.num = num;
        order.customer = customer;
        order.route = route;
        order.transport = transport;
        order.date = date;
        order.exclude = exclude;
        return order;
    }
}

export default OrderFactory;